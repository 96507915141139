import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[dobValidateDirective]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DOBValidateDirective,
    multi: true
  }]
})
export class DOBValidateDirective implements Validator {
  validate(control: AbstractControl) : {[key: string]: any} | null {
    var dob = new Date(control.value);
    var today = new Date();

    if(dob >= today) {
      return { 'dobInvalid': true };
    }

    return null;

  }
}