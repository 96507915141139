import { Component, OnInit } from '@angular/core';
import { 
  NgbActiveModal, NgbModal
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
  templateUrl: './documentDetails.html'
})

export class NgbdModalContent { 
  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-confidential',
  templateUrl: './confidential.component.html',
  styleUrls: ['./confidential.component.scss']
})

export class ConfidentialComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  public openConfidentialDetails() {
    const modalRef = this.modalService.open(NgbdModalContent, { size: 'xl', backdrop: 'static' });
    modalRef.componentInstance.name = 'World';
  }

}
