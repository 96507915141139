import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomService } from 'src/services/custom.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbInputDatepickerConfig,
  NgbTypeahead
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-intake-form',
  templateUrl: './intake-form.component.html',
  styleUrls: ['./intake-form.component.scss']
})

export class IntakeFormComponent implements OnInit {
  public myform: any;
  public showStep1: boolean = false;
  public showPatientData: boolean = false;
  public showUSAConfirmationPage: boolean = false;
  public showHCPPart1: boolean = false;
  public showHCPPCCPart1: boolean = false;
  public showHCPPart2: boolean = false;
  public showEuropeData: boolean = false;
  public showSubmitSuccess: boolean = false;

  public isValidated: boolean = true;
  public checkedDisclaimer: boolean = false;
  public submitEnable: boolean = false;
  public reCaptchaTicked: string = "";
  public showSpinner: boolean = false;
  public maxdate: any = "";
  public formConfidentiality: boolean = false;
  public dateInvalid: boolean = false;
  public emailMismatch: boolean = false;

  public cipersonphoneInvalid: boolean = false;
  public cipersonCellNumberInvalid: boolean = false;
  public ihlclinicPhoneInvalid: boolean = false;
  public ShipINDDrugdeliveryPhoneInvalid: boolean = false;
  public ShipINDDrugdeliveryCellPhoneInvalid: boolean = false;
  public PIDOBInvalid: boolean = false;


  public personName: string;
  public personPhone: string = "";
  public personEmail: string = "";
  public personCellNumber: string = "";
  public personRelationshiptoHCP: string = "";

  public clinicName: string = "";
  public physicianName: string = "";
  public clinicAddress: string = "";
  public clinicCity: string = "";
  public clinicState: string = "";
  public clinicCountry: string = "";
  public countryMismatch: boolean = false;
  public clinicPostcode: string = "";
  public clinicPhone: string = "";
  public clinicEmail: string = "";
  public clinicEmailVerify: string = "";

  public deliveryClinicName: string = "";
  public deliveryName: string = "";
  public deliveryAddress: string = "";
  public deliveryEmail: string = "";
  public deliveryPhone: string = "";
  public deliveryCellPhone: string = "";

  public patientInitials: string = "";
  public patientDateOfBirth: string = "";
  public patientSex: string = "";
  public patientPregnant: boolean = null;
  public patientBriefClinicalCourse: string = "";
  //public patientExposureHistory: string = "";
  public patientCurrentClinicalStatus: string = "";
  public patientImagingResults: string = "";
  public patientPhysicalExamFindings: string = "";
  public patientLabBUN: string = "";
  public patientLabCreatinine: string = "";
  public patientLabCreatinineClear: string = "";
  public patientLabALT: string = "";
  public patientLabAST: string = "";
  public patientLabAlkaline: string = "";
  public patientLabBilirubin: string = "";
  public patientLabCurWeight: string = "";

  public patientSARSCOV2Confirm: boolean = null;
  public patientRequireVentilation: boolean = null;
  public patientVentSettings: string = "";
  public patientRequireECMOSupport: boolean = null;
  public patientECMOType: string = "";
  public patientVasoInoSupport: boolean = null;
  public patientOnMedication: boolean = null;
  public patientOnDialysis: boolean = null;
  public patientPastMedHistory: string = "";
  //public patientALTLevel: boolean = null;
  public patientCreatinineClearance: boolean = null;

  public countryOptions = [];

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.instance && !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ?
        this.countryOptions.map(item => { return item.country })
        :
        this.countryOptions.filter(v => v.country.toLowerCase().indexOf(term.toLowerCase()) > -1).map(item => { return item.country })
      ).slice(0, 10))
    );
  }

  ngOnInit(): void { }

  constructor(private customService: CustomService) {
    this.showStep1 = true;
    this.showPatientData = false;
    this.showEuropeData = false;
    this.showHCPPart1 = false;
    this.showHCPPCCPart1 = false;
    this.showHCPPart2 = false;
    this.showSubmitSuccess = false;
  }

  public showHomePage(event) {
    event.preventDefault();
    this.showStep1 = true;
    this.showUSAConfirmationPage = false;
    this.showPatientData = false;
    this.showEuropeData = false;
    this.showHCPPart1 = false;
    this.showHCPPCCPart1 = false;
    this.showHCPPart2 = false;
    this.showSubmitSuccess = false;
  }

  //public showPatientMessage(event) {
  //  event.preventDefault();
  //  this.showStep1 = false;
  //  this.showPatientData = true;
  //  this.showEuropeData = false;
  //  this.showHCPPart1 = false;
  //  this.showHCPPCCPart1 = false;
  //  this.showHCPPart2 = false;
  //  this.showSubmitSuccess = false;
  //}

  public showEuropeMessage(event) {
    event.preventDefault();
    this.showStep1 = false;
    this.showPatientData = false;
    this.showEuropeData = true;
    this.showHCPPart1 = false;
    this.showHCPPCCPart1 = false;
    this.showHCPPart2 = false;
    this.showSubmitSuccess = false;
  }

  //public showHCPPart1Message(event) {
  //  event.preventDefault();
  //  this.showStep1 = false;
  //  this.showPatientData = false;
  //  this.showEuropeData = false;
  //  this.showHCPPart1 = true;
  //  this.showHCPPCCPart1 = false;
  //  this.showHCPPart2 = false;
  //  this.showSubmitSuccess = false;
  //}

  public showUSAConfirmation(event) {
    event.preventDefault();
    this.showStep1 = false;
    this.showPatientData = false;

    this.showUSAConfirmationPage = true;

    this.showEuropeData = false;
    this.showHCPPart1 = false;
    this.showHCPPCCPart1 = false;
    this.showHCPPart2 = false;
    this.showSubmitSuccess = false;
  }

  public showHCPPCCPart1Message(event) {
    event.preventDefault();
    this.showStep1 = false;
    this.showPatientData = false;
    this.showEuropeData = false;
    this.showHCPPart1 = false;
    this.showHCPPCCPart1 = true;
    this.showHCPPart2 = false;
    this.showSubmitSuccess = false;
  }

  public showHCPPart1_1D(event) {
    event.preventDefault();
    this.showStep1 = false;
    this.showPatientData = false;
    this.showEuropeData = false;
    this.showHCPPart1 = false;
    this.showHCPPCCPart1 = false;
    this.showHCPPart2 = true;
    this.showSubmitSuccess = false;
    let dateTodayTemp: Date = new Date();
    let dateToday = dateTodayTemp.getFullYear() + "-" + (('0' + (dateTodayTemp.getMonth() + 1)).slice(-2)) + "-" + (('0' + (dateTodayTemp.getDate() - 1)).slice(-2));
    this.maxdate = dateToday;
    //this.maxdate = { year: dateTodayTemp.getFullYear(), month: dateTodayTemp.getMonth() + 1, day: dateTodayTemp.getDate() - 1 };

    this.customService.getCountriesData().subscribe(
      data => {
        if (data.length > 0) {
          data.map(countryItem => {
            //if (countryItem.isEnabled) {
            this.countryOptions.push({ country: countryItem.name, code: countryItem.isoCode, enable: countryItem.isEnabled });
            //}
          })
        }
      },
      error => {
      });
  }

  public showHCPPart2Data(event) {
    event.preventDefault();
    this.showStep1 = false;
    this.showPatientData = false;
    this.showEuropeData = false;
    this.showHCPPart1 = false;
    this.showHCPPCCPart1 = false;
    this.showHCPPart2 = true;
    this.showSubmitSuccess = false;
    let dateTodayTemp: Date = new Date();
    let dateToday = dateTodayTemp.getFullYear() + "-" + (('0' + (dateTodayTemp.getMonth() + 1)).slice(-2)) + "-" + (('0' + (dateTodayTemp.getDate() - 1)).slice(-2));
    this.maxdate = dateToday;
    //this.maxdate = { year: dateTodayTemp.getFullYear(), month: dateTodayTemp.getMonth() + 1, day: dateTodayTemp.getDate() - 1 };
    if (this.countryOptions.length == 0) {
      this.customService.getCountriesData().subscribe(
        data => {
          if (data.length > 0) {
            data.map(countryItem => {
              //if (countryItem.isEnabled) {
              this.countryOptions.push({ country: countryItem.name, code: countryItem.isoCode, enable: countryItem.isEnabled });
              //}
            })
          }
        },
        error => {
        });
    }
  }

  public resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.submitEnable = false;
    this.reCaptchaTicked = captchaResponse;
    if (this.checkedDisclaimer && this.reCaptchaTicked) {
      this.submitEnable = true;
    }
  }

  public patientPreg(event): void {
    if (event.target.id == "patientPregnantYes") {
      this.patientPregnant = true;
    }
    else {
      this.patientPregnant = false;
    }
  }

  public requireECMO(event): void {
    if (event.target.id == "patientRequireECMOSupportYes") {
      this.patientRequireECMOSupport = true;
    }
    else {
      this.patientRequireECMOSupport = false;
    }
  }

  public requireInotropic(event): void {
    if (event.target.id == "patientVasoInoSupportYes") {
      this.patientVasoInoSupport = true;
    }
    else {
      this.patientVasoInoSupport = false;
    }
  }

  public OnMedications(event): void {
    if (event.target.id == "patientOnMedicationsYes") {
      this.patientOnMedication = true;
    }
    else {
      this.patientOnMedication = false;
    }
  }

  public OnDialysis(event): void {
    if (event.target.id == "patientOnDialysisYes") {
      this.patientOnDialysis = true;
    }
    else {
      this.patientOnDialysis = false;
    }
  }

  // public ALTLevels(event): void {
  //   if (event.target.id == "patientALTLevelYes") {
  //     this.patientALTLevel = true;
  //   }
  //   else {
  //     this.patientALTLevel = false;
  //   }
  // }

  public CreatinineClearance(event): void {
    if (event.target.id == "patientCreatinineClearanceYes") {
      this.patientCreatinineClearance = true;
    }
    else {
      this.patientCreatinineClearance = false;
    }
  }

  public sarsCOVConfirm(event): void {
    if (event.target.id == "patientSARSCOV2ConfirmYes") {
      this.patientSARSCOV2Confirm = true;
    }
    else {
      this.patientSARSCOV2Confirm = false;
    }
  }

  public requireMedVent(event): void {
    if (event.target.id == "patientRequireVentilationYes") {
      this.patientRequireVentilation = true;
    }
    else {
      this.patientRequireVentilation = false;
    }
  }

  public checkDisclaimer(event): void {
    if (event.target.checked) {
      this.checkedDisclaimer = true;
    }
    else {
      this.checkedDisclaimer = false;
    }
    this.submitEnable = false;

    if (this.checkedDisclaimer && this.reCaptchaTicked) {
      this.submitEnable = true;
    }
  }

  public matchemail(event) {
    this.emailMismatch = false;
    if (this.clinicEmail != this.clinicEmailVerify) {
      this.emailMismatch = true;
    }
  }

  public datecheck(event) {
    this.dateInvalid = false;
    const datePattern = /^(19[\d][\d]|20([0-1][\d]|20))[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    let patientDOB = null;
    if (this.patientDateOfBirth && this.patientDateOfBirth["year"]) {
      patientDOB = new Date(
        this.patientDateOfBirth["year"] + "-" +
        (('0' + (this.patientDateOfBirth["month"])).slice(-2)) + "-" +
        (('0' + (this.patientDateOfBirth["day"])).slice(-2))
      );
      var dateString = this.patientDateOfBirth["year"] + "-" +
        (('0' + (this.patientDateOfBirth["month"])).slice(-2)) + "-" +
        (('0' + (this.patientDateOfBirth["day"])).slice(-2));
      let dateTodayTemp: Date = new Date();
      let dateToday = new Date(dateTodayTemp.getFullYear() + "-" +
        (('0' + ((dateTodayTemp.getMonth() + 1))).slice(-2)) + "-" +
        (('0' + (dateTodayTemp.getDate())).slice(-2))
      );
      if (!patientDOB || patientDOB >= dateToday || patientDOB.getFullYear() < 1900) {
        if (datePattern.test(dateString)) {
          this.dateInvalid = true;
          this.patientDateOfBirth = null;
        } else {
          this.patientDateOfBirth = null;
        }
      }
    }
    else {
      this.patientDateOfBirth = null;
    }
  }

  public countrycheck(event) {
    var inputItem = "";
    if (event.item) {
      inputItem = event.item.toLowerCase();
    }
    else {
      inputItem = this.clinicCountry.toLowerCase();
    }

    if (inputItem) {
      var selectedcountry = this.countryOptions.filter(v => v.country.toLowerCase().indexOf(inputItem) > -1)
      if (selectedcountry.length == 0 || selectedcountry.length > 1) {
        this.clinicCountry = "";
        this.countryMismatch = true;
      }
      else if (selectedcountry[0].country.toLowerCase() != inputItem) {
        this.clinicCountry = "";
        this.countryMismatch = true;
      }
      else {
        this.clinicCountry = selectedcountry[0].country;
        this.countryMismatch = false;
      }
    }
  }

  // Phone Number Validations
  public cipersonphonecheck(event) {
    this.cipersonphoneInvalid = false;
    if (event.currentTarget.value.length > 0) {
      if (event.currentTarget.validity.tooShort) {
        this.cipersonphoneInvalid = !event.currentTarget.checkValidity();
      }
      else {
        this.cipersonphoneInvalid = this.InvalidPhoneCheck(event.currentTarget.value, event.currentTarget.pattern);
      }
    }
  }
  public cipersonCellNumbercheck(event) {
    this.cipersonCellNumberInvalid = false;
    if (event.currentTarget.value.length > 0) {
      if (event.currentTarget.validity.tooShort) {
        this.cipersonCellNumberInvalid = !event.currentTarget.checkValidity();
      }
      else {
        this.cipersonCellNumberInvalid = this.InvalidPhoneCheck(event.currentTarget.value, event.currentTarget.pattern);
      }
    }
  }
  public ihlclinicPhonecheck(event) {
    this.ihlclinicPhoneInvalid = false;
    if (event.currentTarget.value.length > 0) {
      if (event.currentTarget.validity.tooShort) {
        this.ihlclinicPhoneInvalid = !event.currentTarget.checkValidity();
      }
      else {
        this.ihlclinicPhoneInvalid = this.InvalidPhoneCheck(event.currentTarget.value, event.currentTarget.pattern);
      }
    }
  }
  public ShipINDDrugdeliveryPhonecheck(event) {
    this.ShipINDDrugdeliveryPhoneInvalid = false;
    if (event.currentTarget.value.length > 0) {
      if (event.currentTarget.validity.tooShort) {
        this.ShipINDDrugdeliveryPhoneInvalid = !event.currentTarget.checkValidity();
      }
      else {
        this.ShipINDDrugdeliveryPhoneInvalid = this.InvalidPhoneCheck(event.currentTarget.value, event.currentTarget.pattern);
      }
    }
  }
  public ShipINDDrugdeliveryCellPhonecheck(event) {
    this.ShipINDDrugdeliveryCellPhoneInvalid = false;
    if (event.currentTarget.value.length > 0) {
      if (event.currentTarget.validity.tooShort) {
        this.ShipINDDrugdeliveryCellPhoneInvalid = !event.currentTarget.checkValidity();
      }
      else {
        this.ShipINDDrugdeliveryCellPhoneInvalid = this.InvalidPhoneCheck(event.currentTarget.value, event.currentTarget.pattern);
      }
    }
  }

  public dateofbirthfout(event) {
    this.PIDOBInvalid = false;
    if (event.currentTarget.value.length > 0) {
      if (typeof(event.currentTarget.validity.tooShort)=='boolean') {
        this.PIDOBInvalid = !event.currentTarget.checkValidity();
      }
      else {        
        this.PIDOBInvalid = !this.validateDate(event.currentTarget.value);
        if (!this.PIDOBInvalid) {
          let maxdate = new Date();
          let sdate = new Date(event.currentTarget.value);
          if (sdate > maxdate) {
            this.PIDOBInvalid = true;
          }          
        }
      }
    }
  }

  public validateDate(valdate) {
    var date_regex = /^(0[1-9]|1[0-2])[-/](0[1-9]|1\d|2\d|3[01])[-/](19|20)\d{2}$/;
    return date_regex.test(valdate);
  }

  public InvalidValueCheck(val, pat) {
    var m = RegExp(pat);
    if (!m.test(val)) {
      return true;
    }
    return false;
  }

  public InvalidPhoneCheck(val, pat) {
    var m = RegExp(pat + "{9,20}");
    if (!m.test(val)) {
      return true;
    }
    return false;
  }

  public showSubmitSuccessData(event) {
    const phonePattern = /^[0-9-.+()][0-9-.+() ]*[0-9-.+()]$/;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const alphaWithoutSpace = /^[a-zA-Z.]*$/;
    const datePattern = /^(19[\d][\d]|20([0-1][\d]|20))[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    this.dateInvalid = false;

    var countryCode = "";
    var countryEnable = "";
    if (this.clinicCountry) {
      countryCode = this.countryOptions.filter(v => v.country.toLowerCase().indexOf(this.clinicCountry.toLowerCase()) > -1).map(item => { return item.code })[0];
      countryEnable = this.countryOptions.filter(v => v.country.toLowerCase().indexOf(this.clinicCountry.toLowerCase()) > -1).map(item => { return item.enable })[0];
    }

    var dateValidation = true;

    //for the date picker control
    // let patientDOB = null;
    // console.log("this.patientDateOfBirth: " + this.patientDateOfBirth);
    // if (this.patientDateOfBirth && this.patientDateOfBirth["year"]) {
    //   patientDOB = new Date(
    //     this.patientDateOfBirth["year"] + "-" +
    //     (('0' + (this.patientDateOfBirth["month"])).slice(-2)) + "-" +
    //     (('0' + (this.patientDateOfBirth["day"])).slice(-2))
    //   );
    //   var dateString = this.patientDateOfBirth["year"] + "-" +
    //     (('0' + (this.patientDateOfBirth["month"])).slice(-2)) + "-" +
    //     (('0' + (this.patientDateOfBirth["day"])).slice(-2));
    //   console.log("dateString: " + dateString);
    //   console.log("patientDOB: " + patientDOB);
    //   let dateTodayTemp: Date = new Date();
    //   let dateToday = new Date(dateTodayTemp.getFullYear() + "-" +
    //     (('0' + ((dateTodayTemp.getMonth() + 1))).slice(-2)) + "-" +
    //     (('0' + (dateTodayTemp.getDate())).slice(-2))
    //   );
    //   console.log("dateToday: " + dateToday);
    //   if (patientDOB >= dateToday || patientDOB.getFullYear() < 1900) {
    //     dateValidation = false;
    //     if (datePattern.test(dateString)) {
    //       this.dateInvalid = true;
    //     }
    //   }
    // }
    // else {
    //   dateValidation = false;
    // }

    //for normal browser date picker
    let dateTemp: Date = new Date(this.patientDateOfBirth);
    let date = new Date(dateTemp.getFullYear() + "-" + (dateTemp.getMonth() + 1) + "-" + dateTemp.getDate());
    let dateTodayTemp: Date = new Date();
    let dateToday = new Date(dateTodayTemp.getFullYear() + "-" + (dateTodayTemp.getMonth() + 1) + "-" + dateTodayTemp.getDate());
    if (date >= dateToday || date.getFullYear() < 1900) {
      dateValidation = false;
    }

    if (!this.clinicEmail) {
      this.emailMismatch = true;
    }

    this.showSpinner = true;
    event.preventDefault();
    var validationflag = true;
    var validationMessage = "";
    var data = {
      ID: 1,
      ciFullName: this.personName ? this.personName.trim() : (validationflag = false, validationMessage = validationMessage + "Requestor Name\n"),
      //ciPhone: this.personPhone && phonePattern.test(this.personPhone) && this.personPhone.length > 9 ? this.personPhone.trim() : (validationflag = false, validationMessage = validationMessage + "Requestor Phone\n"),
      ciPhone: this.personPhone && !this.cipersonphoneInvalid ? this.personPhone.trim() : (validationflag = false, validationMessage = validationMessage + "Requestor Phone\n"),
      cieMail: this.personEmail && emailPattern.test(this.personEmail) ? this.personEmail.trim() : (validationflag = false, validationMessage = validationMessage + "Requestor Email\n"),
      //ciCellNumber: this.personCellNumber && phonePattern.test(this.personCellNumber) && this.personCellNumber.length > 9 ? this.personCellNumber.trim() : (validationflag = false, validationMessage = validationMessage + "Requestor Cell Number\n"),
      ciCellNumber: this.personCellNumber && !this.cipersonCellNumberInvalid ? this.personCellNumber.trim() : (validationflag = false, validationMessage = validationMessage + "Requestor Cell Number\n"),
      ciRelationshiptoHCP: this.personRelationshiptoHCP ? this.personRelationshiptoHCP : (validationflag = false, validationMessage = validationMessage + "Relationship to HCP\n"),

      ihlHospitalClinicName: this.clinicName ? this.clinicName.trim() : (validationflag = false, validationMessage = validationMessage + "Institution/Physician - Hospital / Clinic Name\n"),
      ihlTreatingPhysicianFullName: this.physicianName ? this.physicianName.trim() : (validationflag = false, validationMessage = validationMessage + "Primary Treating Physician Name\n"),
      ihlAddress: this.clinicAddress ? this.clinicAddress : (validationflag = false, validationMessage = validationMessage + "Institution/Physician Address\n"),
      ihlCity: this.clinicCity ? this.clinicCity.trim() : (validationflag = false, validationMessage = validationMessage + "City\n"),
      ihlStateProvience: this.clinicState ? this.clinicState.trim() : (validationflag = false, validationMessage = validationMessage + "State/Province\n"),
      ihlCountry: this.clinicCountry ? this.clinicCountry.trim() : (validationflag = false, validationMessage = validationMessage + "Country\n"),
      iSOCodeIsEnabled: countryCode ? countryCode + ";" + countryEnable : "",
      ihlPostCode: this.clinicPostcode ? this.clinicPostcode.trim() : (validationflag = false, validationMessage = validationMessage + "Postcode\n"),
      //ihlPhone: this.clinicPhone && phonePattern.test(this.clinicPhone) && this.clinicPhone.length > 9 ? this.clinicPhone.trim() : (validationflag = false, validationMessage = validationMessage + "Institution/Physician Phone\n"),
      ihlPhone: this.clinicPhone && !this.ihlclinicPhoneInvalid ? this.clinicPhone.trim() : (validationflag = false, validationMessage = validationMessage + "Institution/Physician Phone\n"),
      ihleMail: this.clinicEmail && emailPattern.test(this.clinicEmail) ? this.clinicEmail.trim() : (validationflag = false, validationMessage = validationMessage + "Institution/Physician Email\n"),

      shipINDDrugHospitalClinicName: this.deliveryClinicName ? this.deliveryClinicName.trim() : (validationflag = false, validationMessage = validationMessage + "Shipping Address - Hospital / Clinic Name\n"),
      shipINDDrugFullName: this.deliveryName ? this.deliveryName.trim() : (validationflag = false, validationMessage = validationMessage + "Shipping Address Name\n"),
      shipINDDrugAddress: this.deliveryAddress ? this.deliveryAddress : (validationflag = false, validationMessage = validationMessage + "Shipping Address\n"),
      shipINDDrugEMail: this.deliveryEmail && emailPattern.test(this.deliveryEmail) ? this.deliveryEmail.trim() : (validationflag = false, validationMessage = validationMessage + "Shipping Address Email\n"),
      //shipINDDrugPhone: this.deliveryPhone && phonePattern.test(this.deliveryPhone) && this.deliveryPhone.length > 9 ? this.deliveryPhone.trim() : (validationflag = false, validationMessage = validationMessage + "Shipping Address Phone\n"),
      shipINDDrugPhone: this.deliveryPhone && !this.ShipINDDrugdeliveryPhoneInvalid ? this.deliveryPhone.trim() : (validationflag = false, validationMessage = validationMessage + "Shipping Address Phone\n"),
      //shipINDDrugCellPhone: this.deliveryCellPhone && phonePattern.test(this.deliveryCellPhone) && this.deliveryCellPhone.length > 9 ? this.deliveryCellPhone.trim() : (validationflag = false, validationMessage = validationMessage + "Shipping Address Cell Phone\n"),
      shipINDDrugCellPhone: this.deliveryCellPhone && !this.ShipINDDrugdeliveryCellPhoneInvalid ? this.deliveryCellPhone.trim() : (validationflag = false, validationMessage = validationMessage + "Shipping Address Cell Phone\n"),

      piPatientInitials: this.patientInitials && alphaWithoutSpace.test(this.patientInitials) && this.patientInitials.length > 1 ? this.patientInitials.trim() : (validationflag = false, validationMessage = validationMessage + "Patient initials\n"),
      piDateofBirth: this.patientDateOfBirth && dateValidation ? this.patientDateOfBirth : (validationflag = false, validationMessage = validationMessage + "Date of birth is mandatory and should be less than today's date & greater than 12/31/1899\n"),
      //piDateofBirth: this.patientDateOfBirth && dateValidation ? patientDOB : (validationflag = false, validationMessage = validationMessage + "Date of birth is mandatory and should be less than today's date & greater than 1899/12/31\n"),
      piBriefClinicalCourse: this.patientBriefClinicalCourse ? this.patientBriefClinicalCourse : (validationflag = false, validationMessage = validationMessage + "Brief clinical course\n"),
      piGender: this.patientSex ? this.patientSex : (validationflag = false, validationMessage = validationMessage + "Patient Sex\n"),
      piCurrentClinicalStatus: this.patientCurrentClinicalStatus ? this.patientCurrentClinicalStatus : (validationflag = false, validationMessage = validationMessage + "Current clinical status\n"),
      piImagingResults: this.patientImagingResults ? this.patientImagingResults : (validationflag = false, validationMessage = validationMessage + "Imaging results\n"),
      piExamFindingsVitalSigns: this.patientPhysicalExamFindings ? this.patientPhysicalExamFindings : (validationflag = false, validationMessage = validationMessage + "Pertinent physical exam findings/vital signs\n"),
      //piExposureHistory: this.patientExposureHistory ? this.patientExposureHistory : (validationflag = false, validationMessage = validationMessage + "Please mention labs to be reported\n"),
      PILIBUN: this.patientLabBUN ? this.patientLabBUN : (validationflag = false, validationMessage = validationMessage + "Details for BUN lab\n"),
      PILICreatinine: this.patientLabCreatinine ? this.patientLabCreatinine : (validationflag = false, validationMessage = validationMessage + "Details for Creatinine lab\n"),
      PILICreatinineClearance: this.patientLabCreatinineClear ? this.patientLabCreatinineClear : (validationflag = false, validationMessage = validationMessage + "Details for Creatinine Clearance lab\n"),
      PILIALT: this.patientLabALT ? this.patientLabALT : (validationflag = false, validationMessage = validationMessage + "Details for ALT lab\n"),
      PILIAST: this.patientLabAST ? this.patientLabAST : (validationflag = false, validationMessage = validationMessage + "Details for AST lab\n"),
      PILIAlkalinePhosphatase: this.patientLabAlkaline ? this.patientLabAlkaline : (validationflag = false, validationMessage = validationMessage + "Details for Alkaline Phosphatase lab\n"),
      PILITotalBilirubin: this.patientLabBilirubin ? this.patientLabBilirubin : (validationflag = false, validationMessage = validationMessage + "Details for Total Bilirubin lab\n"),
      PILICurrentWeight: this.patientLabCurWeight ? this.patientLabCurWeight : (validationflag = false, validationMessage = validationMessage + "Details for Current Weight lab\n"),

      aisarscovConfirmed: this.patientSARSCOV2Confirm == null ? (validationflag = false, validationMessage = validationMessage + "Was SARS-CoV2 confirmed by PCR?\n") : this.patientSARSCOV2Confirm,
      piPregnancy: this.patientPregnant == null ? (validationflag = false, validationMessage = validationMessage + "Is patient currently pregnant?\n") : this.patientPregnant,
      aiMechanicalVentilation: this.patientRequireVentilation == null ? (validationflag = false, validationMessage = validationMessage + "Currently requiring mechanical ventilation (ie Intubated or Tracheostomy)?\n") : this.patientRequireVentilation,
      aiVentSettings: this.patientRequireVentilation ? (this.patientVentSettings ? this.patientVentSettings : (validationflag = false, validationMessage = validationMessage + "Please list vent settings\n")) : "",
      aiecmoSupport: this.patientRequireECMOSupport == null ? (validationflag = false, validationMessage = validationMessage + "Requiring ECMO support?\n") : this.patientRequireECMOSupport,
      aiecmoType: this.patientRequireECMOSupport ? (this.patientECMOType ? this.patientECMOType : (validationflag = false, validationMessage = validationMessage + "Please indicate support type\n")) : "",
      aiVasopressorInotropicSupport: this.patientVasoInoSupport == null ? (validationflag = false, validationMessage = validationMessage + "Requiring vasopressor or inotropic support?\n") : this.patientVasoInoSupport,
      piMedicationsforCov: this.patientOnMedication == null ? (validationflag = false, validationMessage = validationMessage + "Is the patient taking any of the following medications?\n") : this.patientOnMedication,
      piDialysisorVenoVenousHemofiltration: this.patientOnDialysis == null ? (validationflag = false, validationMessage = validationMessage + "Is patient on dialysis or Continuous Veno-Venous Hemofiltration?\n") : this.patientOnDialysis,
      pmhPastMedicalHistory: this.patientPastMedHistory ? this.patientPastMedHistory : (validationflag = false, validationMessage = validationMessage + "Past Medical History\n"),
      //AIALTlevels: this.patientALTLevel == null ? (validationflag = false, validationMessage = validationMessage + "Are the ALT levels <5x the upper limit of normal?\n") : this.patientALTLevel,
      AICreatinineClearance: this.patientCreatinineClearance == null ? (validationflag = false, validationMessage = validationMessage + "Is Creatinine Clearance >30 mL/min?\n") : this.patientCreatinineClearance,

      CDAConfirmation: this.formConfidentiality ? this.formConfidentiality : (validationflag = false, validationMessage = validationMessage + "Confirmation for confidentiality\n"),
      DateOfRequest: new Date()
    }

    if (this.clinicEmail && this.emailMismatch) {
      validationflag = false;
      validationMessage = validationMessage + "Institution/Physician Email Verification\n";
    }

    if (validationflag) {
      this.customService.submitPatientRequest(data).subscribe(
        data => {
          this.showSpinner = false;
          this.showStep1 = false;
          this.showPatientData = false;
          this.showEuropeData = false;
          this.showHCPPart1 = false;
          this.showHCPPCCPart1 = false;
          this.showHCPPart2 = false;
          this.showSubmitSuccess = true;
        },
        error => {
          this.showSpinner = false;
          var errorString = "";
          if (error.error.modelState) {
            let arr = [];
            Object.keys(error.error.modelState).map(function (key) {
              arr.push({ ["model"]: error.error.modelState[key] })
              return arr;
            });
            if (arr.length) {
              arr.map(item => {
                errorString = errorString + " " + item.model[item.model.length - 1] + "\n";
              })
            }
          }
          else if (error.error.message) {
            errorString = error.error.message;
          }

          errorString = errorString.replace("CICellNumber", "Requestor Cell Number")
            .replace("ShipINDDrugCellPhone", "Shipping Address Cell Phone")
            .replace("ShipINDDrugPhone", "Shipping Address Phone")
            .replace("IHLPhone", "Institution/Physician Phone")
            .replace("CIPhone", "Requestor Phone")
            .replace("CIEMail", "Requestor E-mail")
            .replace("ShipINDDrugEMail", "Shipping Address E-Mail")
            .replace("IHLEMail", "Institution/Physician E-Mail")

          console.log("Error occurred: ", error);
          alert("Submit unsuccessful, see validation message below:\n" + errorString)
        }
      );
    }
    else {
      this.isValidated = false;
      this.showSpinner = false;
      alert("Please provide details for following fields and try to submit again:\n" + validationMessage)
    }
  }
}
