import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { IntakeFormComponent } from './intake-form/intake-form.component';
import { AppRoutingModule } from './app-routing.module';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, NG_VALIDATORS } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfidentialComponent } from './confidential/confidential.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { DOBValidateDirective } from 'src/directive/dob-validator';

@NgModule({
  declarations: [
    AppComponent,
    IntakeFormComponent,
    ConfidentialComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    RecaptchaModule
  ],
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DOBValidateDirective,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
