<style>
  ol {
    padding-left: 12px;
  }

  ol li {
    margin-bottom: 7px;
  }
</style>

<div class="modal-header">
  <div style="width: 100%;" class="text-center">
    <img src="assets/images/GCP_Primarylarge.jpg" height="75" alt="">
  </div>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12 text-right"><strong><em>Confidential</em></strong></div>
  <div class="col-12 text-justify pl-5 pr-5">
    <h5 class="text-center"><strong>GILEAD SCIENCES, INC.</strong></h5>
    <h5 class="text-center"><strong>CONFIDENTIAL DISCLOSURE AGREEMENT</strong></h5>
    <br>
    <p>This Confidential Disclosure Agreement (the <strong>&ldquo;Agreement&rdquo;</strong>) is effective as of
      today(the
      &ldquo;<strong>Effective Date</strong>&rdquo;) by and between you (<strong>&ldquo;Recipient &rdquo;</strong>), and
      <strong>Gilead Sciences, Inc. </strong>, a Delaware corporation with offices at 333 Lakeside Drive, Foster City,
      California 94404, USA (together with its affiliates and subsidiaries, <strong>&ldquo;Gilead &rdquo;</strong>).</p>
    <ol>
      <li><strong>&ldquo;Confidential</strong> <strong>Information&rdquo; </strong>means all nonpublic information
        disclosed in oral, written, electronic or other form or otherwise learned by Recipient under this Agreement,
        including but not limited to information on Gilead&rsquo;s research, development, preclinical and clinical
        programs, data and results; pharmaceutical or biologic candidates and products; inventions, works of authorship,
        trade secrets, processes, conceptions, formulas, patents, patent applications, and licenses; business, product,
        marketing, sales, scientific and technical strategies, programs and results, including costs and prices;
        suppliers, manufacturers, customers, market data, personnel, and consultants; and other confidential matters
        related to</li>
      <li>Confidential Information is being disclosed solely to enable the parties to evaluate the potential emergency
        treatment of one or more patients infected with COVID-19 with Gilead&rsquo;s proprietary compound, remdesivir,
        and
        in furtherance of any such treatment (the <strong>&ldquo;Purpose&rdquo;</strong>).</li>
      <li>Recipient agrees to:
        <ol style="list-style: lower-alpha;">
          <li>use such Confidential Information solely for the Purpose and for no other purpose;</li>
          <li>hold such Confidential Information in confidence and not to disclose such Confidential Information to
            others,
            except for its employees, consultants, agents or representatives who require Confidential
            Information in order to carry out the Purpose and who are subject to binding obligations of confidentiality
            and
            restricted use at least as protective as those of this Agreement;</li>
          <li>protect the confidentiality of such Confidential Information using at least the same level of efforts and
            measures used to protect its own valuable confidential information, and at least commercially
            reasonable efforts and measures; and</li>
          <li>notify Gilead as promptly as practicable of any unauthorized use or disclosure of such Confidential
            Information of which Recipient becomes aware.</li>
        </ol>
      </li>
      <p>In addition, Recipient shall not disclose to any other person (except for employees, consultants and
        representatives as permitted by Section 3(b) above) either the existence of this Agreement, the fact that
        investigations, discussions or negotiations are or may be taking place concerning a possible transaction or any
        facts related thereto.</p>
      <li>The obligations of Section 3 shall not apply to any Confidential Information that:
        <ol style="list-style: lower-alpha;">
          <li>Recipient knew before learning it under this Agreement, as demonstrated by written records predating the
            date it was learned under this Agreement;</li>
          <li>is now, or becomes in the future, publicly available except by an act or omission of Recipient;</li>
          <li>a third party discloses to Recipient without any restriction on disclosure or breach of confidentiality
            obligations to which such third party is subject; or</li>
          <li>Recipient independently develops without use of or reference to Confidential Information, as demonstrated
            by Recipient’s written records contemporaneous with such development.</li>
        </ol>
      </li>
      <li>Notwithstanding Section 3 above, Recipient may disclose Gilead&rsquo;s Confidential Information to the extent
        and to the persons or entities required under applicable governmental law, rule, regulation or order provided
        that Recipient (a) first gives prompt written notice of such disclosure requirement to Gilead so as to enable
        Gilead to seek any limitations on or exemptions from such disclosure requirement and (b) reasonably cooperates
        at
        Gilead&rsquo;s request in any such effor ts by Gilead.</li>
      <li>Upon the earlier of the completion of Recipient&rsquo;s use of Confidential Information for the Purpose under
        this Agreement or Gilead&rsquo;s request for any reason, Recipient will (a) immediately cease all use of
        Confidential Information and (b) promptly, at Gilead&rsquo;s instruction, either return to Gilead or destroy all
        Confidential Information, including any copies, extracts, summaries, or derivative works containing such
        Confidential Information, and certify in writing to Gilead the completion of such return and/or destruction,
        <em>provided, however, </em>that Recipient may retain one copy in its legal archives solely for the purpose of
        monitoring Recipient&rsquo;s surviving obligations under this Agreement.</li>
      <li>Gilead retains all right, title and interest in and to Confidential This Agreement does not and shall not be
        construed to give Recipient any right or license by implication or otherwise to any Confidential Information or
        under any intellectual property or other rights owned by or licensed to Gilead. Neither party has any obligation
        to continue discussions or negotiations or to enter into any transaction with the other party, and either party
        may terminate discussions or negotiations at any time.</li>
      <li>Recipient&rsquo;s obligations under Section 3 of this Agreement shall expire seven (7) years from the
        Effective Date.</li>
      <li>To the extent any personal information (as defined by applicable law) is provided by Gilead, Recipient agrees
        to comply with applicable data protection and privacy law.</li>
      <li>Recipient acknowledges that any actual or threatened breach of this Agreement will cause Gilead immediate and
        irreparable harm that cannot be adequately compensated by monetary damages and therefore agrees that Gilead
        shall not be required to demonstrate irreparable harm in order to obtain equitable and injunctive relief for
        actual or threatened breach of this Agreement, in addition to any other remedies available at law or equity.
      </li>
      <li>Any purported assignment or delegation by a party of this Agreement in whole or in part without the prior
        written consent of the other party shall be This Agreement shall be binding upon the parties, their successors
        and their permitted assigns.</li>
      <li>This Agreement shall be interpreted and enforced in accordance with the laws of the State of California,
        regardless of any choice of law principles.</li>
    </ol>
    <p>BY CLICKING THE CHECKBOX ON <a href="https://rdvcu.gilead.com" target="_blank">https://rdvcu.gilead.com</a> IN
      CONNECTION WITH YOUR REQUEST FOR COMPASSIONATE ACCESS TO REMDESIVIR, YOU ACKNOWLEDGE YOUR AGREEMENT WITH THESE
      TERMS AND YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT.</p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>