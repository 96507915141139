<div class="row" *ngIf="showStep1">
  <div class="col-sm-12 customcolumnstep1">
    <!-- <h3>Welcome to <br>Gilead Medical Information </h3> -->
  </div>
  <div class="col-sm-12 customcolumnstep1">
    <p>
      Thank you for your interest in emergency treatment with VEKLURY (remdesivir). VEKLURY has been approved or
      authorized for temporary use as a COVID-19 treatment in approximately 50 countries worldwide.
    </p>
    <p>Please note that we cannot guarantee access to VEKLURY. Our ability to provide access to VEKLURY, and the
      timeframe for processing requests and providing investigational medicine, varies from country to country for many
      reasons, including national and local laws as well as health authority requirements.​</p>
    <p>Emergency treatment requests will only be considered when local access is not a feasible option.</p>
    <p>To report an adverse event associated with the compassionate use of VEKLURY, please contact <a
        href="mailto:safety_fc@gilead.com">safety_fc@gilead.com</a>.</p>
  </div>
  <div class="col-sm-12 customcolumnstep1">
    <div class="row">
      <!--<div class="row-sm-6 paddingtop">
        <button type="button" class="btn btn-primary btn-block btn-customnav" (click)="showEuropeMessage($event)">
          I'm a healthcare professional​ in the European Union, United Kingdom and Switzerland
        </button>
      </div>-->
      <!-- <div class="col-sm-6 paddingtop">
        <button type="button" class="btn btn-primary btn-block btn-customnav" (click)="showUSAConfirmation($event)">
          I'm a US healthcare professional
        </button>
      </div> -->
      <div class="col-sm-6 paddingtop mx-auto">
        <button type="button" class="btn btn-primary btn-block btn-customnav" (click)="showHCPPCCPart1Message($event)">
          I’m a healthcare professional outside the USA
        </button>
      </div>
      <!--<div class="col-sm-4 paddingtop">
        <button type="button" class="btn btn-primary btn-block btn-customnav" (click)="showPatientMessage($event)">
          I'm a patient or patient's caregiver
        </button>
      </div>-->
    </div>
  </div>
  <div class="col-sm-12 customcolumnstep1" style="padding-top: 2em;">
    By clicking the above button, you are acknowledging that you are a healthcare professional and that you have read
    and understood the information above.
  </div>
</div>

<!-- Europe -->
<!--<div class="row" *ngIf="showEuropeData">
  <div class="col-sm-12 customcolumnstep2">
    <p>
      In line with <a href="https://www.ema.europa.eu/documents/press-release/call-pool-research-resources-large-multi-centre-multi-arm-clinical-trials-generate-sound-evidence_en.pdf">
        European Medicines Agency (EMA) guidance
      </a>issued on 16 March, Gilead is transitioning the mechanism by which physicians secure access to emergency treatment for severely ill patients with COVID-19 with remdesivir.
    </p>
    <p>
      For patients who are unable to enroll in a remdesivir clinical trial, Gilead will now provide emergency access to
      remdesivir via an expanded use protocol. This larger program will both accelerate access to remdesivir for severely
      ill patients and enable the collection of data from all participating patients. This program is currently under review
      by EMA and national regulatory authorities. More details on how to participate in the expanded access protocol will be
      shared upon regulatory approval.
    </p>
    <p>
      Individual compassionate use requests are no longer being accepted in the European Union, United Kingdom or Switzerland.
      We continue to process existing requests and expect the expanded access protocol will initiate in a similar expected timeframe
      for processing new requests.
    </p>
    <p>
      Given the importance of data generation, we urge physicians to enroll patients in clinical trials if reasonably possible
      rather than pursue an emergency treatment request. Please refer to the links below for information on current clinical trials
      investigating the use of remdesivir in COVID-19:
    </p>
  </div>
  <div class="col-sm-12 customcolumnstep2">
    <ul>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04280705">NCT04280705</a></li>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04292730">NCT04292730</a></li>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04292899">NCT04292899</a></li>
      <li><a href="https://www.clinicaltrialsregister.eu/ctr-search/trial/2020-000936-23/FR#G">2020-000936-23</a></li>
    </ul>
  </div>
  <div class="col-sm-12 customcolumnstep2">
    <div class="row">
      <div class="col-sm-6 offset-sm-3 align-self-center">
        <button type="button" class="btn btn-primary btn-block btn-customnav"
                (click)="showHomePage($event)">
          &#x3c;&nbsp;Back
        </button>
      </div>
    </div>
  </div>
</div>-->
<!-- -->
<!--USA confirmation page-->
<div class="row" *ngIf="showUSAConfirmationPage">
  <div class="col-sm-12 customcolumnstep2"><span style="font-weight:bold;color:#c73442;">To access VEKLURY (remdesivir)
      in the US:</span></div>
  <div class="col-sm-12 customcolumnstep2">
    <a href="https://www.veklury.com" target="_blank">www.veklury.com​</a>
    <!--<ul>
    <li>VEKLURY is a prescription medicine to treat COVID-19 in adults and children at least 12 years old and weighing
      at least 40 kg requiring hospitalization</li>
  </ul>
  <a href="https://www.vekluryhcp.com" target="_blank">www.vekluryhcp.com​</a>
  <ul>
    <li>‘Emergency Use Authorization’ is in place to treat suspected or laboratory-confirmed SARS-CoV-2 in
      hospitalized pediatric patients weighing 3.5 kg to less than 40 kg or hospitalized pediatric patients less than
      12 years of age weighing at least 3.5 kg</li>
  </ul>
  <span>Additional information</span>
  <ul>
    <li>
      For patients weighing < 3.5 kg, requests sent to <a href="mailto:RDV-CU-Reviewer@gilead.com">
        RDV-CU-Reviewer@gilead.com</a> will be reviewed by Gilead’s Compassionate Use Response Team on a case-by-case
        basis</li>
  </ul>-->
    <ul>
      <li>
        VEKLURY is a prescription medicine to treat COVID-19 in adults and children (28 days of age and older
        and
        weighing at least 3 kg) with
        positive results of direct SARS-Cov-2 viral testing, who are:
        <ul>
          <li>Hospitalized, or</li>
          <li>
            Not hospitalized and have mild-to-moderate COVID-19, and are at high risk for progression to
            severe
            COVID-19, including hospitalization or death
          </li>
        </ul>
      </li>
    </ul>
    <span>Additional information</span>
    <ul>
      <li>
        For patients < 28 days of age and/or weighing < 3 kg, requests sent to <a
          href="mailto:RDV-CU-Reviewer@gilead.com">
          RDV-CU-Reviewer@gilead.com
          </a> will be reviewed by Gilead’s Compassionate Use Response Team on a case-by-case
          basis
      </li>
    </ul>
  </div>
  <div class="col-sm-12 customcolumnstep2">
    <div class="row">
      <div class="col-sm-6 offset-sm-3 align-self-center">
        <button type="button" class="btn btn-primary btn-block btn-customnav" (click)="showHomePage($event)">
          &#x3c;&nbsp;Back
        </button>
      </div>
    </div>
  </div>
</div>
<!--USA confirmation page-->

<!--Patient's Page-->
<!--<div class="row" *ngIf="showPatientData">
  <div class="col-sm-12 customcolumnstep2">
    Thank you for your inquiry. We recognize that you are interested in emergency access to remdesivir for you or a loved one, and we understand the urgency. The mechanism to receive emergency access to remdesivir is currently being transitioned and we are working closely with regulatory agencies to expedite this work. More information will be shared with healthcare providers as quickly as possible. At the same time, we are working around the clock to generate data to inform the broader use of remdesivir as a potential treatment for COVID-19. We acknowledge this is a challenging situation, and we appreciate your understanding and patience.
  </div>
  <div class="col-sm-12 customcolumnstep2">
    Please see below for public health resources and other information:
  </div>
  <div class="col-sm-12 customcolumnstep2">
    <ul>
      <li>World Health Organization (WHO):<a href="https://www.who.int/">https://www.who.int/</a></li>
      <li>
        Centers for Disease Control and Prevention (CDC):<a href="https://www.cdc.gov/">https://www.cdc.gov/</a>
      </li>
    </ul>
  </div>
  <div class="col-sm-12 customcolumnstep2">
    <div class="row">
      <div class="col-sm-6 offset-sm-3 align-self-center">
        <button type="button" class="btn btn-primary btn-block btn-customnav"
                (click)="showHomePage($event)">
          &#x3c;&nbsp;Back
        </button>
      </div>
    </div>
  </div>
</div>-->
<!--Patient's Page-->
<!--Criteria Page-->
<!--<div class="row" *ngIf="showHCPPart1">
  <div class="col-sm-12 customcolumnstep2">
    Gilead is transitioning the provision of emergency access to remdesivir from individual compassionate use requests to expanded access programs. This approach will both accelerate access to remdesivir for severely ill patients and enable the collection of data from all participating patients. These programs are currently under rapid development in conjunction with national regulatory authorities worldwide. More details on how to participate in the expanded access programs will be forthcoming.
  </div>
  <div class="col-sm-12 customcolumnstep2">
    During this transition period, we are unable to accept new individual compassionate use requests due to an overwhelming demand over the last several days. We are focused now on processing previously approved  requests and anticipate the expanded access programs will initiate in a similar expected timeframe that any new requests for compassionate use would have been processed.
  </div>
  <div class="col-sm-12 customcolumnstep2" style="text-align:center;"><span style="font-weight:bold;color:#c73442; text-align:center">Exceptions will be made only for pregnant women or children less than 18 years of age with confirmed COVID-19 and severe manifestations of disease.</span></div>
  <div class="col-sm-12 customcolumnstep2">
    Given the importance of data generation, we urge you to enroll patients in clinical trials if reasonably possible rather than pursue an emergency treatment request. Please refer to the links below for information on current clinical trials investigating the use of remdesivir in COVID-19:
    <ul>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04280705" target="_blank">NCT04280705​</a></li>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04292730" target="_blank">NCT04292730</a></li>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04292899" target="_blank">NCT04292899</a></li>
      <li><a href="https://www.clinicaltrialsregister.eu/ctr-search/trial/2020-000936-23/FR" target="_blank">2020-000936-23</a></li>
    </ul>
  </div>
  <div class="col-sm-12 customcolumnstep2">
    Thank you for your understanding as we work through this transition as rapidly as possible. We are grateful for all that you are doing to serve patients in your community as we work collectively to respond to this global health crisis.
  </div>
  <div class="col-sm-12 customcolumnstep2">
    <div class="row">
      <div class="col-sm-6 paddingtop">
        <button type="button" class="btn btn-primary btn-block btn-customnav"
                (click)="showHomePage($event)">
          &#x3c;&nbsp;Back
        </button>
      </div>
      <div class="col-sm-6 paddingtop">
        <button type="button" class="btn btn-primary btn-block btn-customnav"
                (click)="showHCPPCCPart1Message($event)">
          Next&nbsp;&#x3E;
        </button>
      </div>
    </div>
  </div>
</div>-->
<!--Criteria Page-->
<!--Pregnant or Child Confirmation Page-->
<div class="row" *ngIf="showHCPPCCPart1">
  <div class="col-sm-12 customcolumnstep2" style="text-align:center;">
    <span style="font-weight:bold;color:#c73442; text-align:center">
      By clicking the box below, I confirm that I am
      submitting this for a patient under the age of 28 days or weighing &#x3c;&nbsp;40 kg.
    </span>
  </div>
  <div class="col-sm-12 customcolumnstep2">
    VEKLURY(remdesivir) is indicated for the treatment of coronavirus disease 2019 (COVID-19) in adults and pediatric
    patients. Recently, the European
    Medicines Agency (EMA) and Medicines and Healthcare products Regulatory Agency (MHRA, UK) have adopted a positive
    opinion to extend the
    indication of Veklury (remdesivir) for the treatment of pediatric patients (weighing at least 40 kg) who do not
    require supplemental oxygen and are at
    increased risk of progressing to severe COVID-19 and pediatric patients 4 weeks of age and older and weighing at
    least 3 kg with SARS-CoV-2 with
    pneumonia who require supplemental oxygen (low- or high-flow oxygen or other non-invasive ventilation at the start
    of treatment).
  </div>
  <div class="col-sm-12 customcolumnstep2">
    The European
    Medicines Agency approved indication for VEKLURY is available at <a
      href="https://www.ema.europa.eu/en/medicines/human/EPAR/veklury"
      target="_blank">https://www.ema.europa.eu/en/medicines/human/EPAR/veklury</a>
    In July 2022, the Committee for Medicinal Products for Human Use (CHMP) of the European Commission (EC) adopted a
    positive opinion
    recommending Remdesivir receive full marketing authorisation for the treatment of patients with COVID-19
  </div>
  <!--<div class="col-sm-12 customcolumnstep2">
    VEKLURY showed a clinically meaningful effect on time to recovery in COVID-19 patients with pneumonia requiring
    supplemental oxygen, while being well tolerated with mild side effects. The European Medicines Agency (“Agency”)
    therefore decided that VEKLURY’s benefits are greater than its risks and that it can be authorised for use in the
    EU.
  </div>
  <div class="col-sm-12 customcolumnstep2">
    In the context of the public health emergency and the urgent need for effective treatments for COVID-19, VEKLURY has
    been given a ‘<a href="https://www.ema.europa.eu/en/glossary/conditional-marketing-authorisation"
      title="The approval of a medicine that address unmet medical needs of patients on the basis of less comprehensive data than normally required. The available data must indicate that the medicine’s benefits outweigh its risks and the applicant should be in a position to provide the comprehensive clinical data in the future."
      target="_blank">conditional marketing authorisation</a>’. This means that there is more evidence to come about the
    medicine, which Gilead Sciences is required to provide. The Agency will review any new information that becomes
    available and this overview will be updated as necessary.
  </div>-->

  <div class="col-sm-12 customcolumnstep2" style="text-align:center;">
    <span style="font-weight:bold; text-align:center">
      Gilead’s Compassionate Use Program for VEKLURY remains open to pediatric patients residing outside of
      the US where local access may not be feasible. Please click below to submit an application for eligibility review.
      Please allow 24-hours for a response to your Compassionate Use application.
    </span>
  </div>
  <div class="col-sm-12 customcolumnstep2">
    <div class="row">
      <div class="col-sm-6 customcolumnstep2">
        <button type="button" class="btn btn-primary btn-block btn-customnav" (click)="showHomePage($event)"
          style="padding: 5.5% 0 5.5% 0;">
          &#x3c;&nbsp;Back
        </button>
      </div>
      <div class="col-sm-6 customcolumnstep2">
        <button type="button" class="btn btn-primary btn-block btn-customnav" (click)="showHCPPart2Data($event)">
          I CONFIRM THAT I AM SUBMITTING THIS REQUEST FOR A PATIENT WHO IS EITHER &#x3c;&nbsp;AGE 28 DAYS of age or
          &#x3c;&nbsp;40 kg
        </button>
      </div>
    </div>
  </div>
</div>
<!--Pregnant or Child Confirmation Page-->

<div class="row example-wrapper" *ngIf="showHCPPart2">

  <div class="col-sm-12 customcolumnstep3">
    <p>
      In completing the form below, please limit personal information to what is requested. For all fields, including
      case history, refer to the patient <u>only</u> by his or her initials (e.g., JD), and do not provide the patient's
      full name.
    </p>
    <p>
      Patient confidentiality is of primary importance to us. All patient information will remain confidential.
      Information provided will be handled in accordance with Gilead's privacy policy, available at <a
        href="https://www.gilead.com/privacy">www.gilead.com/privacy</a>.
    </p>
  </div>

  <!--Contact Information Section-->
  <form name="myform" class="needs-validation" [ngClass]="{'was-validated': !isValidated}">
    <div class="col-md-12">
      <h5 class="mb-3">Contact Information for Requestor (Not Patient)</h5>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="personName">Name<span style="color: red;">*</span></label>
          <input autofocus [(ngModel)]="personName" name="personName" type="text" class="form-control" id="personName"
            autocomplete="new-personName" required>
          <div class="invalid-feedback">
            Name is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="personPhone">Phone<span style="color: red;">*</span></label>
          <input [(ngModel)]="personPhone" name="personPhone" type="text" class="form-control" id="personPhone" required
            minlength="10" maxlength="20" pattern="[0-9-.+()][0-9-.+() ]*[0-9-.+()]" autocomplete="new-personPhone"
            (keyup)="cipersonphonecheck($event)" (focusout)="cipersonphonecheck($event)"
            [ngClass]="{'invalidControl': cipersonphoneInvalid}" />
          <div *ngIf="cipersonphoneInvalid; else elsecipersonphoneInvalid"
            style="color: #dc3545;font-size: 80%;width: 100%;">
            Phone number is invalid, length must be between 10-20 characters & no blank space at the begining & ending.
          </div>
          <ng-template #elsecipersonphoneInvalid>
            <div class="invalid-feedback">
              Phone number is required.
              <!--, length must be between 10-20 characters & no blank space at the begining & ending.-->
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="personEmail">E-mail<span style="color: red;">*</span></label>
          <input [(ngModel)]="personEmail" name="personEmail" type="text" class="form-control" id="personEmail" required
            autocomplete="new-personEmail" pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}">
          <div class="invalid-feedback">
            Email is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="personCellNumber">Cell Number<span style="color: red;">*</span></label>
          <input [(ngModel)]="personCellNumber" name="personCellNumber" type="text" class="form-control"
            id="personCellNumber" required minlength="10" maxlength="20" pattern="[0-9-.+()][0-9-.+() ]*[0-9-.+()]"
            autocomplete="new-personCellNumber" (keyup)="cipersonCellNumbercheck($event)"
            (focusout)="cipersonCellNumbercheck($event)" [ngClass]="{'invalidControl': cipersonCellNumberInvalid}" />
          <div *ngIf="cipersonCellNumberInvalid; else elsecipersonCellNumberInvalid"
            style="color: #dc3545;font-size: 80%;width: 100%;">
            Cell number is invalid, length must be between 10-20 characters & no blank space at the begining & ending.
          </div>
          <ng-template #elsecipersonCellNumberInvalid>
            <div class="invalid-feedback">
              Cell number is required.
              <!--, length must be between 10-20 characters & no blank space at the begining & ending.-->
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <div class="form-group">
          <label for="personRelationshiptoHCP">
            Relationship to HCP (e.g. coordinator, nurse, pharmacist, etc.)<span style="color: red;">*</span>
          </label>
          <input [(ngModel)]="personRelationshiptoHCP" name="personRelationshiptoHCP" type="text" class="form-control"
            id="personRelationshiptoHCP" required autocomplete="new-personRelationshiptoHCP">
          <div class="invalid-feedback">
            Relationship to HCP is required.
          </div>
        </div>
      </div>
    </div>

    <!--Institution / Hospital Location Section-->
    <div class="col-md-12">
      <h5 class="mb-3">Primary Institution / Primary Physician</h5>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="clinicName">Hospital / Clinic Name<span style="color: red;">*</span></label>
          <input [(ngModel)]="clinicName" name="clinicName" type="text" class="form-control" id="clinicName" required
            autocomplete="new-clinicName">
          <div class="invalid-feedback">
            Hospital / Clinic Name is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="physicianName">Primary Treating Physician Name<span style="color: red;">*</span></label>
          <input [(ngModel)]="physicianName" name="physicianName" type="text" class="form-control" id="physicianName"
            required autocomplete="new-physicianName">
          <div class="invalid-feedback">
            Primary Treating Physician Name is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="clinicAddress">Address<span style="color: red;">*</span></label>
          <textarea [(ngModel)]="clinicAddress" name="clinicAddress" id="clinicAddress" rows="2" class="form-control"
            required autocomplete="new-clinicAddress"></textarea>
          <div class="invalid-feedback">
            Address is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="clinicCity">City<span style="color: red;">*</span></label>
          <input [(ngModel)]="clinicCity" name="clinicCity" type="text" class="form-control" id="clinicCity" required
            autocomplete="new-clinicCity">
          <div class="invalid-feedback">
            City is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="clinicState">State/Province<span style="color: red;">*</span></label>
          <input [(ngModel)]="clinicState" name="clinicState" type="text" class="form-control" id="clinicState" required
            autocomplete="new-clinicState">
          <div class="invalid-feedback">
            State/Province is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="clinicCountry">Country<span style="color: red;">*</span></label>
          <!-- <input [(ngModel)]="clinicCountry" name="clinicCountry" type="text" class="form-control" id="clinicCountry"
          required autocomplete="new-clinicCountry"> -->
          <input id="clinicCountry" type="text" class="form-control" [(ngModel)]="clinicCountry" name="clinicCountry"
            [ngbTypeahead]="search" (focus)="focus$.next($event.target.value)"
            (click)="click$.next($event.target.value)" #instance="ngbTypeahead" required
            autocomplete="new-clinicCountry" (change)="countrycheck($event)" (selectItem)="countrycheck($event)"
            [ngClass]="{'invalidControl': countryMismatch}" />
          <div class="invalid-feedback">
            Country is required.
          </div>
          <div *ngIf="countryMismatch" style="color: #dc3545;font-size: 80%;width: 100%;">
            Please select a valid country.
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="clinicPostcode">Postcode<span style="color: red;">*</span></label>
          <input [(ngModel)]="clinicPostcode" name="clinicPostcode" type="text" class="form-control" id="clinicPostcode"
            required autocomplete="new-clinicPostcode">
          <div class="invalid-feedback">
            Postcode is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="clinicPhone">Physician Phone<span style="color: red;">*</span></label>
          <input [(ngModel)]="clinicPhone" name="clinicPhone" type="text" class="form-control" id="clinicPhone" required
            minlength="10" maxlength="20" pattern="[0-9-.+()][0-9-.+() ]*[0-9-.+()]" autocomplete="new-clinicPhone"
            (keyup)="ihlclinicPhonecheck($event)" (focusout)="ihlclinicPhonecheck($event)"
            [ngClass]="{'invalidControl': ihlclinicPhoneInvalid}" />
          <div *ngIf="ihlclinicPhoneInvalid; else elseihlclinicPhoneInvalid"
            style="color: #dc3545;font-size: 80%;width: 100%;">
            Physician Phone number is invalid, length must be between 10-20 characters & no blank space at the begining
            & ending.
          </div>
          <ng-template #elseihlclinicPhoneInvalid>
            <div class="invalid-feedback">
              Physician Phone number is required.
              <!--, length must be between 10-20 characters & no blank space at the begining & ending.-->
            </div>
          </ng-template>

          <!--<div class="invalid-feedback">
            Physician Phone is required, length must be between 10-20 characters & no blank space at the begining &
            ending.
          </div>-->
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="clinicEmail">Physician E-Mail<span style="color: red;">*</span></label>
          <input [(ngModel)]="clinicEmail" name="clinicEmail" type="text" class="form-control" id="clinicEmail" required
            autocomplete="new-clinicEmail" pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
            (keyup)="matchemail($event)">
          <div class="invalid-feedback">
            Physician Email is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="clinicEmailVerify">Verify Physician E-Mail<span style="color: red;">*</span></label>
          <input [(ngModel)]="clinicEmailVerify" id="clinicEmailVerify" name="clinicEmailVerify" type="text"
            class="form-control" autocomplete="new-clinicEmailVerify" (keyup)="matchemail($event)"
            (paste)="$event.preventDefault()" (cut)="$event.preventDefault()" (copy)="$event.preventDefault()"
            [ngClass]="{'invalidControl': emailMismatch}">
          <div class="invalid-feedback">
            Verify Physician Email is required.
          </div>
          <div *ngIf="emailMismatch" style="color: #dc3545;font-size: 80%;width: 100%;">
            Physician Email Verification does not match.
          </div>
        </div>
      </div>
    </div>

    <!--Delivery Address Section-->
    <div class="col-md-12">
      <h5 class="mb-3">If Approved - Where are we shipping the investigational drug?</h5>
      <p>
        <i>
          To whom are we delivering the product (The contact below must be reachable with 24 hour availability. Please
          ensure that the Pharmacy address is as specific as possible.)
        </i>
      </p>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="deliveryClinicName">Pharmacy/Hospital Name<span style="color: red;">*</span></label>
          <input [(ngModel)]="deliveryClinicName" name="deliveryClinicName" type="text" class="form-control"
            id="deliveryClinicName" required>
          <div class="invalid-feedback">
            Pharmacy/Hospital Name is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="deliveryName">Pharmacist/Pharmacy Contact Name<span style="color: red;">*</span></label>
          <input [(ngModel)]="deliveryName" name="deliveryName" type="text" class="form-control" id="deliveryName"
            required>
          <div class="invalid-feedback">
            Pharmacist/Pharmacy Contact Name is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="deliveryAddress">Address<span style="color: red;">*</span></label>
          <textarea required [(ngModel)]="deliveryAddress" name="deliveryAddress" id="deliveryAddress" rows="2"
            class="form-control" autocomplete="new-deliveryAddress"></textarea>
          <div class="invalid-feedback">
            Address is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="deliveryEmail">E-Mail<span style="color: red;">*</span></label>
          <input [(ngModel)]="deliveryEmail" name="deliveryEmail" type="text" class="form-control" id="deliveryEmail"
            required name="new-deliveryEmail" pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}">
          <div class="invalid-feedback">
            E-Mail is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="deliveryPhone">Phone<span style="color: red;">*</span></label>
          <input [(ngModel)]="deliveryPhone" name="deliveryPhone" type="text" class="form-control" id="deliveryPhone"
            required minlength="10" maxlength="20" pattern="[0-9-.+()][0-9-.+() ]*[0-9-.+()]"
            autocomplete="new-deliveryPhone" (keyup)="ShipINDDrugdeliveryPhonecheck($event)"
            (focusout)="ShipINDDrugdeliveryPhonecheck($event)"
            [ngClass]="{'invalidControl': ShipINDDrugdeliveryPhoneInvalid}" />
          <div *ngIf="ShipINDDrugdeliveryPhoneInvalid; else elseShipINDDrugdeliveryPhoneInvalid"
            style="color: #dc3545;font-size: 80%;width: 100%;">
            Phone number is invalid, length must be between 10-20 characters & no blank space at the begining & ending.
          </div>
          <ng-template #elseShipINDDrugdeliveryPhoneInvalid>
            <div class="invalid-feedback">
              Phone number is required.
              <!--, length must be between 10-20 characters & no blank space at the begining & ending.-->
            </div>
          </ng-template>
          <!--<div class="invalid-feedback">
            Phone is required, length must be between 10-20 characters & no blank space at the begining & ending.
          </div>-->
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="deliveryCellPhone">
            Cell Phone (Very important for accurate courier delivery)<span style="color: red;">*</span>
          </label>
          <input [(ngModel)]="deliveryCellPhone" name="deliveryCellPhone" type="text" class="form-control"
            id="deliveryCellPhone" required minlength="10" maxlength="20" pattern="[0-9-.+()][0-9-.+() ]*[0-9-.+()]"
            autocomplete="new-deliveryCellPhone" (keyup)="ShipINDDrugdeliveryCellPhonecheck($event)"
            (focusout)="ShipINDDrugdeliveryCellPhonecheck($event)"
            [ngClass]="{'invalidControl': ShipINDDrugdeliveryCellPhoneInvalid}" />
          <div *ngIf="ShipINDDrugdeliveryCellPhoneInvalid; else elseShipINDDrugdeliveryCellPhoneInvalid"
            style="color: #dc3545;font-size: 80%;width: 100%;">
            Cell Phone number is invalid, length must be between 10-20 characters & no blank space at the begining &
            ending.
          </div>
          <ng-template #elseShipINDDrugdeliveryCellPhoneInvalid>
            <div class="invalid-feedback">
              Cell Phone number is required.
              <!--, length must be between 10-20 characters & no blank space at the begining & ending.-->
            </div>
          </ng-template>

          <!--<div class="invalid-feedback">
            Cell Phone is required, length must be between 10-20 characters & no blank space at the begining & ending.
          </div>-->
        </div>
      </div>
    </div>

    <!--Patient Information Section-->
    <div class="col-md-12">
      <h5 class="mb-3">Patient Information</h5>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientInitials">Patient initials<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientInitials" name="patientInitials" type="text" class="form-control"
            id="patientInitials" required minlength="2" maxlength="3" autocomplete="new-patientInitials"
            pattern="[a-zA-Z.]*">
          <div class="invalid-feedback">
            Patient initials is required & must be 2-3 characters length.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- <div class="form-group">
          <div class="input-group">
            <label style="width:100%;" for="patientDateOfBirth">Date of birth (yyyy-mm-dd)<span
                style="color: red;">*</span></label>
            <input required class="form-control covid-datepick" placeholder="yyyy-mm-dd"
              onkeydown="const allowedChar = /^[0-9-]$/; if(allowedChar.test(event.key) || event.key == 'Backspace' || event.key == 'Delete' || event.key == 'ArrowRight' || event.key=='ArrowLeft') {return true;} else {return false;}"
              name="patientDateOfBirth" [(ngModel)]="patientDateOfBirth" ngbDatepicker #d="ngbDatepicker"
              [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="maxdate" autocomplete="new-patientDateOfBirth"
              pattern="(19[\d][\d]|20([0-1][\d]|20))[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])"
              (change)="datecheck($event)" dobValidateDirective>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar covid-datepick-btn" (click)="d.toggle()"
                type="button"></button>
            </div>
            <div class="invalid-feedback">
              Date of birth is required and should be less than today's date & greater than 1899/12/31.
            </div>
          </div>
        </div> -->

        <div class="form-group">
          <label for="patientDateOfBirth">Date of birth<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientDateOfBirth" name="patientDateOfBirth" type="date" class="form-control"
            id="patientDateOfBirth" required autocomplete="new-patientDateOfBirth" min='1900-01-01' max={{this.maxdate}}
            (focusout)="dateofbirthfout($event)" [ngClass]="{'invalidControl': PIDOBInvalid}" />
          <!-- (keyup)="dateofbirthfout($event)"  -->
          <div *ngIf="PIDOBInvalid; else elsePIDOBInvalid" style="color: #dc3545;font-size: 80%;width: 100%;">
            Date of birth is invalid, and should be less than today's date & greater than 12/31/1899.
          </div>
          <ng-template #elsePIDOBInvalid>
            <div class="invalid-feedback">
              Date od birth is required.
              <!--and should be less than today's date & greater than 12/31/1899.-->
            </div>
          </ng-template>
          <!--<div class="invalid-feedback">
            Date of birth is required and should be less than today's date & greater than 12/31/1899.
          </div>-->
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientBriefClinicalCourse">Brief clinical course<span style="color: red;">*</span></label>
          <textarea required [(ngModel)]="patientBriefClinicalCourse" name="patientBriefClinicalCourse"
            id="patientBriefClinicalCourse" rows="2" class="form-control"
            autocomplete="new-patientBriefClinicalCourse"></textarea>
          <div class="invalid-feedback">
            Brief clinical course is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientSex">Patient Sex<span style="color: red;">*</span></label>
          <select [(ngModel)]="patientSex" name="patientSex" class="custom-select d-block w-100" id="patientSex"
            required>
            <option value="" selected disabled>Please select</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
          <div class="invalid-feedback">
            Patient Sex is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientCurrentClinicalStatus">Current clinical status<span style="color: red;">*</span></label>
          <textarea required [(ngModel)]="patientCurrentClinicalStatus" name="patientCurrentClinicalStatus"
            id="patientCurrentClinicalStatus" rows="2" class="form-control"
            autocomplete="new-patientCurrentClinicalStatus"></textarea>
          <div class="invalid-feedback">
            Current clinical status is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientImagingResults">Imaging results<span style="color: red;">*</span></label>
          <textarea required [(ngModel)]="patientImagingResults" name="patientImagingResults" id="patientImagingResults"
            rows="2" class="form-control" autocomplete="new-patientImagingResults"></textarea>
          <div class="invalid-feedback">
            Imaging results is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <div class="form-group">
          <label for="patientPhysicalExamFindings">
            Pertinent physical exam findings/vital signs (Must include current
            supplemental oxygen requirement). For patients < 18 years of age, please list current weight in kg.​<span
              style="color: red;">*</span>
          </label>
          <textarea required [(ngModel)]="patientPhysicalExamFindings" name="patientPhysicalExamFindings"
            id="patientPhysicalExamFindings" rows="2" class="form-control"
            autocomplete="new-patientPhysicalExamFindings"></textarea>
          <div class="invalid-feedback">
            Pertinent physical exam findings/vital signs is required.
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="form-group">
          <label for="patientExposureHistory">The following labs must be reported. Please include units of measure.
            (BUN, Creatinine, Creatinine Clearance, ALT, AST, Alkaline Phosphatase, Total Bilirubin)<span
              style="color: red;">*</span></label>
          <textarea required [(ngModel)]="patientExposureHistory" name="patientExposureHistory" id="patientExposureHistory"
            rows="2" class="form-control" autocomplete="new-patientExposureHistory"></textarea>
          <div class="invalid-feedback">
            Exposure history is required.
          </div>
        </div>
      </div> -->
    </div>

    <!--Labs Section-->
    <div class="col-md-12">
      <h5 class="mb-3">Labs</h5>
      <p><i>The following labs must be reported. Please include units of measure.</i></p>
    </div>
    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientLabBUN">BUN​<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientLabBUN" name="patientLabBUN" type="text" class="form-control" id="patientLabBUN"
            required>
          <div class="invalid-feedback">
            Details for BUN lab is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientLabCreatinine">Creatinine<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientLabCreatinine" name="patientLabCreatinine" type="text" class="form-control"
            id="patientLabCreatinine" required>
          <div class="invalid-feedback">
            Details for Creatinine lab is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientLabCreatinineClear">Creatinine Clearance<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientLabCreatinineClear" name="patientLabCreatinineClear" type="text"
            class="form-control" id="patientLabCreatinineClear" required>
          <div class="invalid-feedback">
            Details for Creatinine Clearance lab is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientLabALT">ALT<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientLabALT" name="patientLabALT" type="text" class="form-control" id="patientLabALT"
            required>
          <div class="invalid-feedback">
            Details for ALT lab is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientLabAST">AST<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientLabAST" name="patientLabAST" type="text" class="form-control" id="patientLabAST"
            required>
          <div class="invalid-feedback">
            Details for AST lab is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientLabAlkaline">Alkaline Phosphatase<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientLabAlkaline" name="patientLabAlkaline" type="text" class="form-control"
            id="patientLabAlkaline" required>
          <div class="invalid-feedback">
            Details for Alkaline Phosphatase lab is required.
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12">
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientLabBilirubin">Total Bilirubin<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientLabBilirubin" name="patientLabBilirubin" type="text" class="form-control"
            id="patientLabBilirubin" required>
          <div class="invalid-feedback">
            Details for Total Bilirubin lab is required.
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="patientLabCurWeight">Current Weight<span style="color: red;">*</span></label>
          <input [(ngModel)]="patientLabCurWeight" name="patientLabCurWeight" type="text" class="form-control"
            id="patientLabCurWeight" required>
          <div class="invalid-feedback">
            Details for Current Weight lab is required.
          </div>
        </div>
      </div>
    </div>

    <!--Additional Information Section-->
    <div class="col-md-12">
      <h5 class="mb-3">Additional Information</h5>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <label for="patientSARSCOV2Confirm">
          Was SARS-CoV2 confirmed by diagnostic testing?<span style="color: red;">*</span>
        </label>&nbsp;&nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientSARSCOV2ConfirmYes" name="patientSARSCOV2Confirm"
            (change)="sarsCOVConfirm($event)" required>
          <label class="custom-control-label" for="patientSARSCOV2ConfirmYes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientSARSCOV2ConfirmNo" name="patientSARSCOV2Confirm"
            (change)="sarsCOVConfirm($event)" required>
          <label class="custom-control-label" for="patientSARSCOV2ConfirmNo">No</label>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <label for="patientPregnant">
          Is patient currently pregnant?<span style="color: red;">*</span>
        </label>&nbsp;&nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientPregnantYes" name="patientPregnant"
            (change)="patientPreg($event)" required>
          <label class="custom-control-label" for="patientPregnantYes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientPregnantNo" name="patientPregnant"
            (change)="patientPreg($event)" required>
          <label class="custom-control-label" for="patientPregnantNo">No</label>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <label for="patientRequireVentilation">
          Currently requiring mechanical ventilation (ie Intubated or Tracheostomy)?
          <span style="color: red;">*</span>
        </label>&nbsp;&nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientRequireVentilationYes"
            name="patientRequireVentilation" (change)="requireMedVent($event)" required>
          <label class="custom-control-label" for="patientRequireVentilationYes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientRequireVentilationNo"
            name="patientRequireVentilation" (change)="requireMedVent($event)" required>
          <label class="custom-control-label" for="patientRequireVentilationNo">No</label>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12" *ngIf="this.patientRequireVentilation">
        <div class="form-group">
          <label for="patientVentSettings">Please list vent settings<span style="color: red;">*</span></label>
          <textarea [(ngModel)]="patientVentSettings" name="patientVentSettings" id="patientVentSettings" rows="2"
            class="form-control" required></textarea>
          <div class="invalid-feedback">
            Please list vent settings is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <label for="patientRequireECMOSupport">
          Requiring ECMO support?<span style="color: red;">*</span>
        </label>&nbsp;&nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientRequireECMOSupportYes"
            name="patientRequireECMOSupport" (change)="requireECMO($event)" required>
          <label class="custom-control-label" for="patientRequireECMOSupportYes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientRequireECMOSupportNo"
            name="patientRequireECMOSupport" (change)="requireECMO($event)" required>
          <label class="custom-control-label" for="patientRequireECMOSupportNo">No</label>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div *ngIf="patientRequireECMOSupport" class="col-md-12">
        <div class="form-group">
          <label for="patientECMOType">Please indicate support type<span style="color: red;">*</span></label>
          <select [(ngModel)]="patientECMOType" name="patientECMOType" class="custom-select d-block w-100"
            id="patientECMOType" required>
            <option value="" selected disabled>Please select</option>
            <option value="V-V ECMO">V-V ECMO</option>
            <option value="V-A ECMO">V-A ECMO</option>
          </select>
          <div class="invalid-feedback">
            Please indicate support type is required.
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <label for="patientVasoInoSupport">
          Requiring vasopressor or inotropic
          support?<span style="color: red;">*</span>
        </label>&nbsp;&nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientVasoInoSupportYes" name="patientVasoInoSupport"
            (change)="requireInotropic($event)" required>
          <label class="custom-control-label" for="patientVasoInoSupportYes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientVasoInoSupportNo" name="patientVasoInoSupport"
            (change)="requireInotropic($event)" required>
          <label class="custom-control-label" for="patientVasoInoSupportNo">No</label>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <label for="patientOnMedications">
          Is the patient taking any of the following medications? (lopinavir/ritonavir,
          chloroquine, any investigational agents for COVID-19)<span style="color: red;">*</span>
        </label>&nbsp;&nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientOnMedicationsYes" name="patientOnMedications"
            (change)="OnMedications($event)" required>
          <label class="custom-control-label" for="patientOnMedicationsYes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientOnMedicationsNo" name="patientOnMedications"
            (change)="OnMedications($event)" required>
          <label class="custom-control-label" for="patientOnMedicationsNo">No</label>
        </div>
      </div>
    </div>

    <!-- dialysisorVenoVenousHemofiltra -->
    <div class="row col-md-12">
      <div class="col-md-12">
        <label for="patientOnDialysis">
          Is patient on dialysis or Continuous Veno-Venous Hemofiltration?<span style="color: red;">*</span>
        </label>&nbsp;&nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientOnDialysisYes" name="patientOnDialysis"
            (change)="OnDialysis($event)" required>
          <label class="custom-control-label" for="patientOnDialysisYes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientOnDialysisNo" name="patientOnDialysis"
            (change)="OnDialysis($event)" required>
          <label class="custom-control-label" for="patientOnDialysisNo">No</label>
        </div>
      </div>
    </div>

    <!-- New Fields -->
    <!-- <div class="row col-md-12">
      <div class="col-md-12">
        <label for="patientALTLevel">
          Are the ALT levels <5x the upper limit of normal?<span style="color: red;">
            *</span>
        </label>&nbsp;&nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientALTLevelYes" name="patientALTLevel"
                 (change)="ALTLevels($event)" required>
          <label class="custom-control-label" for="patientALTLevelYes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientALTLevelNo" name="patientALTLevel"
                 (change)="ALTLevels($event)" required>
          <label class="custom-control-label" for="patientALTLevelNo">No</label>
        </div>
      </div>
    </div> -->
    <div class="row col-md-12">
      <div class="col-md-12">
        <label for="patientCreatinineClearance">
          Is Creatinine Clearance >30 mL/min?<span style="color: red;">*</span>
        </label>&nbsp;&nbsp;
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientCreatinineClearanceYes"
            name="patientCreatinineClearance" (change)="CreatinineClearance($event)" required>
          <label class="custom-control-label" for="patientCreatinineClearanceYes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="patientCreatinineClearanceNo"
            name="patientCreatinineClearance" (change)="CreatinineClearance($event)" required>
          <label class="custom-control-label" for="patientCreatinineClearanceNo">No</label>
        </div>
      </div>
    </div>


    <div class="row col-md-12">
      <div class="col-md-12">
        <div class="form-group">
          <label for="patientPastMedHistory">Past Medical History<span style="color: red;">*</span></label>
          <textarea required [(ngModel)]="patientPastMedHistory" name="patientPastMedHistory" id="patientPastMedHistory"
            rows="2" class="form-control"></textarea>
          <div class="invalid-feedback">
            Past Medical History is required.
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <p>
        Please note that we cannot guarantee access to remdesivir. Our ability to provide access to remdesivir, and the
        timeframe for processing requests and providing investigational medicine, varies from country to country for
        many reasons, including national and local laws as well as health authority requirements.
      </p>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <div class="form-check">
          <input type="checkbox" name="formConfidentiality" class="form-check-input" id="formConfidentiality" required
            [(ngModel)]="formConfidentiality">
          <label class="form-check-label" for="formConfidentiality">
            <p>
              <span style="color: red;">*</span>I confirm that I have read the <app-confidential></app-confidential>
              and will agree to maintain confidentiality.
            </p>
            <!-- <a href="assets/documents/CDA One-way Gilead remdesivir compassionate use.pdf" target="_blank">CDA</a> -->
          </label>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-md-12">
        <div class="form-check">
          <input type="checkbox" name="formDisclaimer" (change)="checkDisclaimer($event)" class="form-check-input"
            id="formDisclaimer">
          <label class="form-check-label" for="formDisclaimer">
            <p>
              <span style="color: red;">*</span>By checking this box and clicking Submit: I acknowledge that Gilead
              does not guarantee that remdesivir can or will be made available for this patient and that Gilead will
              review each request on an individual basis. I certify that I have received the appropriate consent or
              authorization from the patient, in accordance with applicable privacy and data protection law(s), and any
              other applicable requirements, in order to release the patient’s personal and medical information to
              Gilead and its agents and contractors for the purposes of: 1) evaluating the patient’s eligibility for
              compassionate use; 2) assessing need for compassionate use; 3) directing healthcare professionals and
              patients to available clinical trials; 4) facilitating the provision of the investigational drug to the
              patient, where eligible; 5) Gilead’s internal business purposes; 6) sharing with governmental and
              non-governmental health organizations and entities in furtherance of public health initiatives; and 7)
              complying with applicable legal and regulatory obligations.
            </p>
          </label>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <p class="font-weight-bold">
        Please reach out to <a href="mailto:PortalTechSupport@gilead.com"
          title="mailto:PortalTechSupport@gilead.com">PortalTechSupport@gilead.com</a> for any technical issues with
        this form. Do not send patient information or other requests to email.
      </p>
    </div>

    <div class="row col-md-12">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6 paddingtop">
            <re-captcha (resolved)="resolved($event)" siteKey="6LdFTOEUAAAAAAxjE5qbGCbqWdb9rhZUEQBz6RIB"></re-captcha>
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6 paddingtop">
            <button type="button" class="btn btn-primary btn-block btn-customnav"
              (click)="showHCPPCCPart1Message($event)">
              &#x3c;&nbsp;Back
            </button>
          </div>
          <div class="col-sm-6 paddingtop">
            <button [disabled]="!this.submitEnable" type="submit" class="btn btn-primary btn-block btn-customnav"
              (click)="showSubmitSuccessData($event)">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="this.showSpinner">
    <div class="loading">Loading&#8230;</div>
  </div>
</div>

<div class="row example-wrapper" *ngIf="showSubmitSuccess">
  <div class="col-sm-12 customcolumnstep1">
    Thank you for submitting your case for consideration.
  </div>
  <div class="col-sm-12 customcolumnstep2">
    We cannot process this request if it is not for a child. To learn more about clinical trial sites
    enrolling adult patients please visit the following links:
  </div>
  <div class="col-sm-12 customcolumnstep2">
    <ul>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04280705">NCT04280705</a></li>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04292730">NCT04292730</a></li>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04292899">NCT04292899</a></li>
      <li><a href="https://www.clinicaltrialsregister.eu/ctr-search/trial/2020-000936-23/FR">2020-000936-23</a></li>
      <li><a href="https://clinicaltrials.gov/ct2/show/NCT04323761">NCT04323761</a></li>
    </ul>
  </div>
  <div class="col-sm-12 customcolumnstep1">
    If your submission is for a child, please understand that we recognize the urgency of your request
    and we are doing our very best to evaluate your request and respond to you in a timely manner.
  </div>
</div>