<div class="container">
  <nav class="navbar navbar-light justify-content-md-center">
    <a class="navbar-brand" href="/">
      <img src="assets/images/GCP_Primarylarge.jpg" height="75" alt="">
    </a>
  </nav>
  <!-- <router-outlet></router-outlet> -->
  <app-intake-form></app-intake-form>

  <!-- Footer -->
  <hr>

  <footer class="text-muted text-center text-small">
    <p>We recommend that you use the most up-to-date browser that's compatible with your operating system. The following browsers are supported: Microsoft Edge (latest version), Internet Explorer 11, Safari (latest version, Mac only), Chrome (latest version) and Firefox (latest version)</p>
    <p>If you are experiencing issues with your computer's browser, you may try using your mobile device.</p>
    <p>Please reach out to <a href="mailto:PortalTechSupport@gilead.com">PortalTechSupport@gilead.com</a> for any technical issues with this form. Do not send patient information or other requests to email.</p>
    <p class="mb-1">&copy; 2020 Gilead Sciences, Inc. All rights reserved.</p>
    <ul class="list-inline">
      <li class="list-inline-item"><a href="https://www.gilead.com/privacy-statements">Gilead Privacy
          Statement</a></li>
      <li class="list-inline-item"><a href="https://www.gilead.com/terms-of-use">Terms of Use</a></li>
    </ul>    
  </footer>
</div>